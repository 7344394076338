import Alpine from "alpinejs";

export type ModalAlertType = {
  open: boolean;
  message: string;
  type: string;
  modalType: string;
  toggle: (
    this: ModalAlertType,
    type?: string,
    message?: string,
    modalType?: string
  ) => void;
}

export type ModalMinimalType = {
  isShowClose: boolean;
  setToggle: (
    this: ModalMinimalType,
    type?: string,
    isShow?: boolean,
    isShowClose?: boolean
  ) => void;
}

/**
 * init modal form and alert modal */
export const initFormAlertModal = () => {
  initModalAlert();
  initModalMinimal();
}


export const initModalAlert = () => {
// init data store for show modal
  Alpine.store('modalAlert', {
    open: false,
    message: '',
    type: 'enquiry',
    modalType: 'success',
    toggle(
      this: ModalAlertType,
      type = 'enquiry',
      message = '',
      modalType = 'success'
    ) {
      this.open = !this.open;
      this.message = message;
      this.type = type;
      this.modalType = modalType;
    }
  })
}

export const initModalMinimal = () => {
  Alpine.store('modalMinimal', {
    isShowClose: true,
    setToggle(
      this: ModalMinimalType,
      id: string,
      isShow: boolean,
      isShowClose = true
    ) {
      console.log(id)
      const element = document.getElementById(id);
      this.isShowClose = isShowClose;
      if (element) {
        if (isShow) {
          element.classList.remove('hidden')
        } else {
          element.classList.add('hidden')
        }
      }
    }
  })
}


